import resolveConfig from "tailwindcss/resolveConfig";
import config from "@/../tailwind.config.js";
import ShapeStatusEnum from "@/pages/wellPlate/_providers/types/enums/ShapeStatusEnum";

const fullConfig = resolveConfig(config);

const palette = {
  defaultColor: "#ffffff00", // default color  - transparent
  colorOnSelection: fullConfig.theme.colors.brand["orange"], // orange - when selected color of stroke
  colorProcessedArea: fullConfig.theme.colors.surface["low"], // off-white - used for stroke
  colorError: "#c53030", // red = when something wrong with size
  colorProcessedAreaOnHover: fullConfig.theme.colors.surface["high"], // white - on hover for stroke and fill of area
  colorUnprocessed: fullConfig.theme.colors.brand["orange"] // orange - used to unprocessed areas
};

export const virtualWellPalette = {
  defaultColor: "#ffffff00", // default color  - transparent
  colorOnSelection: fullConfig.theme.colors.signal["high"], // blue - when selected color of stroke
  colorProcessedArea: "#ffffff", // white - used for stroke
  colorWithoutRois: "#E2E8F0", // grey - used for stroke and fill
  colorError: "#c53030", // red - when something wrong with size
  colorProcessedAreaOnHover: fullConfig.theme.colors.signal["base"], // blue - on hover for stroke and fill of area
  colorStrokeProcessed: fullConfig.theme.colors.control["low"], // dark grey used for stroke areas with rois
  colorOfRois: fullConfig.theme.colors.control["low"], // light grey - used for fill and stroke of ROIs
  colorOfWellPlateBackground: fullConfig.theme.colors.surface["info"],
  colorOfWellPlateBackgroundStroke: fullConfig.theme.colors.control["base"] // control-base - used for stroke of well plate background
};

export function getFillColor(shape, isHovered, hasError) {
  if (shape.processed && !isHovered) {
    return palette.defaultColor;
  }
  return shape.processed
    ? palette.colorProcessedAreaOnHover
    : hasError
    ? palette.colorError
    : palette.colorUnprocessed;
}

export function getStrokeColor(
  shape,
  isHovered,
  groupSelected = { shapes: [] }
) {
  if (!shape.visible) return;
  if (shape.status === ShapeStatusEnum.PROCESSING) {
    return "#000";
  }
  if (!shape.selected && isHovered) {
    return palette.colorProcessedAreaOnHover;
  }

  if (!shape.processed && !shape.selected) {
    return palette.colorProcessedArea;
  }
  return groupSelected.shapes.includes(shape) || shape.selected
    ? palette.colorOnSelection
    : shape.processed
    ? palette.colorProcessedArea
    : shape.offBounds
    ? palette.colorError
    : shape.color;
}
export function getOpacity(shape, isHovered) {
  if (!shape.processed && !isHovered) {
    if (shape.selected) {
      return 0.5;
    }
    return 0.2;
  } else if (!shape.processed && isHovered) {
    return 0.4;
  }
  return 0;
}

export function getVirtualStrokeColor(
  shape,
  isHovered,
  selected,
  isROI,
  isMapView
) {
  if ((shape.selected || selected) && isMapView) {
    return palette.colorOnSelection;
  }

  if ((!shape.selected || !selected) && isMapView) {
    if (!isHovered) {
      return palette.colorProcessedArea;
    }
    return palette.colorProcessedAreaOnHover;
  }

  if (!shape.selected && isHovered) {
    return virtualWellPalette.colorProcessedAreaOnHover;
  }
  if (isROI && !selected) return virtualWellPalette.colorOfRois;

  return selected || shape.selected
    ? virtualWellPalette.colorOnSelection
    : shape.processed
    ? virtualWellPalette.colorStrokeProcessed
    : shape.offBounds
    ? virtualWellPalette.colorError
    : shape.color;
}
