/* eslint-disable */
require('./account-box-outline')
require('./account-box')
require('./account-circle')
require('./account-cog')
require('./account-group-outline')
require('./account-group')
require('./account')
require('./alert-circle')
require('./alert')
require('./anchor')
require('./animation')
require('./application')
require('./apps')
require('./arrow-collapse-right')
require('./arrow-collapse')
require('./arrow-expand-left')
require('./arrow-expand')
require('./arrow-left-bold-circle-outline')
require('./arrow-left-bold-circle')
require('./arrow-left-bold')
require('./arrow-left-thick')
require('./arrow-left')
require('./arrow-right-bold-circle-outline')
require('./arrow-right-bold-circle')
require('./arrow-right-bold')
require('./arrow-right-thick')
require('./arrow-right')
require('./battery-alert')
require('./battery-charging')
require('./battery')
require('./bell-off')
require('./bell-ring-outline')
require('./bell-ring')
require('./bell')
require('./blur')
require('./book')
require('./buffer')
require('./bullseye-arrow')
require('./calculator')
require('./camera')
require('./cancel')
require('./certificate')
require('./chart-line')
require('./check-box-outline')
require('./check-circle-outline')
require('./check-circle')
require('./check')
require('./checkbox-blank-circle-outline')
require('./checkbox-blank-circle')
require('./checkbox-blank')
require('./checkbox-multiple-blank-circle-outline')
require('./checkbox-multiple-blank-circle')
require('./checkbox-multiple-blank-outline')
require('./checkbox-multiple-blank')
require('./checkbox-multiple-marked-outline')
require('./cheetah')
require('./chevron-double-left')
require('./chevron-double-right')
require('./chevron-down')
require('./chevron-left')
require('./chevron-right')
require('./chevron-up')
require('./circle-mapview-vector')
require('./circle-outline')
require('./circle')
require('./clipboard')
require('./clock-outline')
require('./clock')
require('./close-circle')
require('./close')
require('./cloud-check')
require('./cloud-lock-outline')
require('./cloud-lock')
require('./cloud-outline')
require('./cloud')
require('./color-wheel')
require('./comment')
require('./compare')
require('./console')
require('./contain')
require('./content-paste')
require('./content-save')
require('./contrast')
require('./cookie')
require('./counter')
require('./crosshairs-off')
require('./crosshairs')
require('./delete-outline')
require('./delete')
require('./device-exact')
require('./device-lux')
require('./device-omni')
require('./devices')
require('./diameter')
require('./doctor')
require('./dots-horizontal')
require('./download-outline')
require('./download')
require('./drag')
require('./draw')
require('./drawing')
require('./eject')
require('./email')
require('./exclamation-thick')
require('./exclamation')
require('./exponent')
require('./export')
require('./eye-off-outline')
require('./eye-off')
require('./eye-outline')
require('./eye')
require('./fa-sort-asc')
require('./fa-sort-desc')
require('./fa-sort')
require('./feature-search-outline')
require('./feature-search')
require('./file-document-box-multiple-outline')
require('./file-document-box-outline')
require('./file-document-box')
require('./file-document-edit-outline')
require('./file-document-edit')
require('./file-document')
require('./file-download-outline')
require('./file-download')
require('./file-replace')
require('./file')
require('./filter')
require('./flag')
require('./flask')
require('./focus-auto')
require('./folder-open')
require('./folder-outline')
require('./folder')
require('./forward')
require('./fullscreen')
require('./gesture-two-double-tap')
require('./gradient')
require('./graph')
require('./grid')
require('./group')
require('./hand')
require('./help')
require('./history')
require('./image')
require('./import')
require('./information-outline')
require('./information-variant')
require('./information')
require('./label-off-outline')
require('./label-off')
require('./label-outline')
require('./label')
require('./launch')
require('./layers-off-outline')
require('./license')
require('./lightbulb-on')
require('./lightbulb-outline')
require('./lightbulb')
require('./link')
require('./loading')
require('./lock-open-outline')
require('./lock-open')
require('./lock-outline')
require('./lock')
require('./locker')
require('./login')
require('./logout-variant')
require('./logout')
require('./magnify-minus')
require('./magnify-plus')
require('./magnify')
require('./mail')
require('./margin')
require('./mass-export-circles')
require('./matrix')
require('./menu-down')
require('./menu')
require('./message')
require('./minus')
require('./monitor')
require('./more-vertical')
require('./more')
require('./mouse')
require('./movie')
require('./network')
require('./note')
require('./opacity')
require('./open-in-new')
require('./pause-circle-outline')
require('./pause-circle')
require('./pause')
require('./pen')
require('./pencil')
require('./percent')
require('./pi')
require('./pin')
require('./play-circle-outline')
require('./play-circle')
require('./play')
require('./plus-box-multiple-outline')
require('./plus')
require('./polygon-mapview-vector')
require('./power')
require('./printer')
require('./publish')
require('./pulse')
require('./radius')
require('./rectangle-mapview-vector')
require('./rectangle')
require('./redo-variant')
require('./redo')
require('./refresh')
require('./reload')
require('./repeat')
require('./resize')
require('./responsive')
require('./restore')
require('./robot-industrial')
require('./router')
require('./ruler')
require('./scatter-plot')
require('./school')
require('./select-all')
require('./select-off')
require('./select-place')
require('./select-search')
require('./select')
require('./selection-off')
require('./selection')
require('./send')
require('./settings-outline')
require('./settings')
require('./share-off-outline')
require('./share')
require('./signal')
require('./sleep')
require('./sort')
require('./square-outline')
require('./square')
require('./star')
require('./stop')
require('./sync')
require('./tag')
require('./thermometer')
require('./timelapse')
require('./tools')
require('./tooltip')
require('./transfer')
require('./trash')
require('./turtle')
require('./undo-variant')
require('./undo')
require('./update')
require('./upload')
require('./video')
require('./view-grid')
require('./view-list')
require('./volume-high')
require('./volume-off')
require('./web')
require('./white-balance-sunny')
require('./wifi-off')
require('./wifi')
require('./window-close')
require('./window-maximize')
require('./window-minimize')
