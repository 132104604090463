import { render, staticRenderFns } from "./VirtualWellPlate.vue?vue&type=template&id=3f40ec7c&scoped=true&"
import script from "./VirtualWellPlate.vue?vue&type=script&lang=ts&"
export * from "./VirtualWellPlate.vue?vue&type=script&lang=ts&"
import style0 from "./VirtualWellPlate.vue?vue&type=style&index=0&id=3f40ec7c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_react-dom@18_vyrjir3tafa6d7xmeapelwsujq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f40ec7c",
  null
  
)

export default component.exports