import { computed, defineComponent, onMounted, ref, watch } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import BErrors from "@/shared/components/forms/BErrors.vue";
import BLabel from "@/shared/components/forms/BLabel.vue";
import { BSearchSelect, WithValidation } from "@axisvue/forms";
import { formValidators, RenderlessTagInput } from "@axisvue/forms";
import { AxionButton } from "@axisvue/buttons";
import { useSSO } from "@/shared/providers/withSSO/withSSO";
const SsoAuthenticationRepository = RepositoryFactory.ssoAuthenticationRepository;
export default defineComponent({
    components: {
        BLabel,
        BErrors,
        WithValidation,
        BSearchSelect,
        RenderlessTagInput,
        AxionButton
    },
    props: {
        customClass: { type: String, required: false, default: "" },
        required: { type: Boolean, required: false, default: true },
        initialEmails: {
            type: Array,
            required: false,
            default: () => []
        },
        excludedEmails: {
            type: Array,
            required: false,
            default: () => []
        },
        usersRepository: {
            type: Object,
            default: () => RepositoryFactory.users
        }
    },
    setup(props, context) {
        const tenantUsers = ref([]);
        const emails = ref([]);
        const { allowedDomains } = useSSO();
        const validators = computed(() => props.required ? ["isEmail", "required"] : ["isEmail"]);
        const formattedInitialEmails = computed(() => props.initialEmails.map(email => ({ Emails: email, Name: "" })));
        const getRemainingEmails = (emails) => {
            const remaining = Object.fromEntries(emails.map(entry => [entry.Email, entry]));
            for (const email of props.excludedEmails) {
                if (remaining[email])
                    delete remaining[email];
            }
            for (const email of props.initialEmails) {
                if (remaining[email])
                    delete remaining[email];
            }
            return Object.values(remaining);
        };
        const getFormattedEmails = (emails) => {
            const formatted = emails.map(user => {
                const obj = {
                    Email: user.Email,
                    Name: ""
                };
                if (user.FirstName && user.LastName)
                    obj.Name = user.FirstName + " " + user.LastName;
                return obj;
            });
            return formatted;
        };
        const sortEmails = () => {
            tenantUsers.value.sort((user1, user2) => {
                const email1 = user1.Email.toLowerCase();
                const email2 = user2.Email.toLowerCase();
                if (email1 < email2) {
                    return -1;
                }
                if (email1 > email2) {
                    return 1;
                }
                return 0;
            });
        };
        const fetchEmails = () => {
            props.usersRepository.getEmailsInTenant().then(r => {
                if (r.data.length === 0)
                    return;
                const remaining = getRemainingEmails(r.data);
                tenantUsers.value = getFormattedEmails(remaining);
                sortEmails();
            });
        };
        // Function to check if each email has an allowed domain
        const isAllowedDomain = (email, domains) => {
            // Check if email is a valid string and contains '@'
            if (!email || !email.includes('@')) {
                return false;
            }
            // Extract the domain part of the email and ensure it is defined
            const emailDomain = email.split('@').pop();
            if (!emailDomain) {
                return false;
            }
            // Check if the email domain is in the list of allowed domains
            return domains.some(domain => emailDomain.endsWith(domain));
        };
        watch(emails, changedEmails => {
            context.emit("change", changedEmails);
        });
        onMounted(() => {
            fetchEmails();
        });
        return {
            isEmail: (input) => formValidators.isEmail(input),
            emails,
            tenantUsers,
            validators,
            formattedInitialEmails,
            isAllowedDomain,
            allowedDomains
        };
    }
});
