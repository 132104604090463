import { LatLng } from "leaflet";
import { determineNewAreaName } from "../shared/shapeHelper";
export default class Circle {
    constructor(id = "Eli" + ++Circle.counter, radius, center, marker, processed, lux3ExperimentId, confluenceThreshold, visible, status) {
        this.type = "circle";
        this.display = false;
        this.visible = true;
        this.id = "";
        this.selected = false;
        this.color = "#F8C47B";
        this.lux3ExperimentId = "";
        this.processed = false;
        this.confluenceThreshold = 0;
        this.offBounds = false;
        this.outOfMapBounds = false;
        this.selectedAlgorithm = null;
        this.id = id;
        this.radius = radius;
        this.center = center;
        this.marker = marker;
        this.processed = processed;
        this.lux3ExperimentId = lux3ExperimentId;
        this.confluenceThreshold = confluenceThreshold;
        this.visible = visible;
        this.status = status;
    }
    moveShape(diff) {
        if (!this.processed) {
            this.center = new LatLng(this.center.lat + diff.lat, this.center.lng + diff.lng);
            this.marker = new LatLng(this.marker.lat + diff.lat, this.marker.lng + diff.lng);
        }
    }
    getCenter() {
        return this.center;
    }
    setProcessed() {
        this.processed = true;
    }
    getProcessed() {
        return this.processed;
    }
    clone(shapes) {
        let shapeId = this.id;
        if (shapes.ids.includes(shapeId)) {
            shapeId = determineNewAreaName("Eli", shapes.ids);
        }
        return new Circle(shapeId, this.radius, this.center, this.marker, this.processed, this.lux3ExperimentId, this.confluenceThreshold, this.visible, this.status);
    }
    setConfluenceThreshold(threshold) {
        this.confluenceThreshold = threshold;
    }
    getConfluenceThreshold() {
        return this.confluenceThreshold;
    }
    changeRadius(radius, marker = this.marker) {
        this.radius = radius;
        this.marker = marker;
    }
    delete() {
        Circle.counter--;
    }
    setColor(color) {
        this.color = color;
    }
    setoffBounds(offBounds) {
        this.offBounds = offBounds;
    }
    setoutOfMapBounds(outOfMapBounds) {
        this.outOfMapBounds = outOfMapBounds;
    }
}
Circle.counter = 0;
