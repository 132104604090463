import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { LCircle, LIcon, LImageOverlay, LMap, LMarker, LPolygon, LRectangle } from "vue2-leaflet";
import { virtualWellPalette } from "@/pages/wellPlate/Mapview/shapes";
import { VirtualWellPlateMode } from "../_providers/types/enums/VirtualWellPlateMode";
import { useVirtualWellplate } from "../_providers/VirtualWellPlateShapeProvider/withVirtualWellplate";
import { useAreas } from "../_providers/withAreas";
import { useExperiment } from "../_providers/withExperiment";
import { useGroups } from "../_providers/withGroups/withGroups";
import { useSelect } from "../_providers/withSelect/withSelect";
import customCRS from "../Mapview/customCRS";
import { generateInnerWellPlateBackground, generateROIs, generateWells, loadROIs, loadShapesVirtualWellPlate, loadWells } from "../Mapview/shapesHelper";
import wellPlateCRS from "./virtualWellPlateCRS";
import navigationCRS from "./wellNavigationPanelCRS";
export default defineComponent({
    components: {
        LMap,
        LCircle,
        LRectangle,
        LPolygon,
        LImageOverlay,
        LMarker,
        LIcon
    },
    props: {
        wellplateDetails: {
            type: Object,
            required: true
        },
        areas: {
            type: Array,
            required: true
        },
        areasData: {
            type: Object,
            required: true
        },
        drag: {
            type: Boolean,
            required: true
        },
        windowWidth: {
            type: Number,
            required: true
        },
        areaSelection: {
            type: Boolean,
            required: true
        },
        options: {
            type: Object,
            default: () => ({
                mode: "fluorescence",
                wellPlateWeight: 1,
                wellPlateOpacity: 0.5,
                wellWeight: 1,
                wellOpacity: 0.7,
                dragging: false,
                zoomControl: false,
                scrollWheelZoom: false,
                initialZoom: 2.5,
                center: [50, 65]
            })
        }
    },
    setup(props, { emit }) {
        const virtualWellPlateProvider = useVirtualWellplate();
        const { experiment } = useExperiment();
        const { groups, getGroupByAreasIds } = useGroups();
        const { areasPositions } = useAreas();
        const selector = useSelect();
        const clicked = ref(false);
        const map = ref(null);
        const crs = virtualWellPlateProvider.data.mode === VirtualWellPlateMode.NAVIGATION
            ? navigationCRS
            : props.options.mode === "fluorescence"
                ? wellPlateCRS
                : customCRS;
        const mapHeight = computed(() => {
            switch (virtualWellPlateProvider.data.mode) {
                case VirtualWellPlateMode.NAVIGATION:
                case VirtualWellPlateMode.PROCESSING:
                    return props.windowWidth / 6 + "px";
                case VirtualWellPlateMode.RESULTS:
                case VirtualWellPlateMode.GROUPING:
                    // different estimated height values based on browser width breakpoints
                    if (props.windowWidth / 4 < 350) {
                        return "350px";
                    }
                    if (props.windowWidth < 1900) {
                        return "480px";
                    }
                    return props.windowWidth / 4 + "px";
                default:
                    return props.windowWidth / 4 + "px";
            }
        });
        const { wells, letters, numbers } = props.options.mode === "fluorescence"
            ? generateWells(props.wellplateDetails)
            : { wells: [], letters: [], numbers: [] };
        const size = ref([0, 0]);
        const wellWeight = 1.5;
        const wellOpacity = 0.7;
        const wellPlateWeight = 1;
        const wellPlateOpacity = 1;
        const dynamicLetterSize = ref("text-md");
        const allWells = computed(() => virtualWellPlateProvider.getAllWells());
        const mapMounted = () => {
            if (map.value) {
                map.value.mapObject.dragging.disable();
            }
        };
        const centerMap = computed(() => {
            return props.options.mode === "fluorescence"
                ? // @ts-expect-error
                    [props.options.center[0], props.options.center[1]]
                : [size.value[0] / 2, size.value[1] / 2];
        });
        const thumbnailUrl = computed(() => {
            return experiment.thumbnailUrl;
        });
        const loadImage = () => {
            const img = new Image();
            img.src = thumbnailUrl.value;
            img.onload = () => {
                size.value = [-(img.naturalHeight / 8e-3), img.naturalWidth / 8e-3];
            };
        };
        const initShapes = () => {
            if (props.options.mode === "fluorescence") {
                virtualWellPlateProvider.clearAllShapes();
                const rois = generateROIs(props.areas, props.areasData);
                loadROIs(rois, virtualWellPlateProvider);
                loadWells(props.areas, wells, virtualWellPlateProvider);
            }
            else {
                virtualWellPlateProvider.clearAllShapes();
                loadShapesVirtualWellPlate(props.areas, virtualWellPlateProvider, props.areasData);
                loadImage();
            }
        };
        const generateWellPlateBackground = function () {
            const height = props.wellplateDetails.height;
            const width = props.wellplateDetails.width;
            const d1 = 5;
            const result = [];
            result.push([d1, 0]);
            result.push([width - d1, 0]);
            result.push([width, d1]);
            result.push([width, height]);
            result.push([d1, height]);
            result.push([0, height]);
            result.push([0, d1]);
            result.push([d1, 0]);
            return result;
        };
        // When zooming out to the last level, pan the map to the center
        const handleZoom = function () {
            var _a, _b, _c;
            const zoomLevel = (_c = (_b = (_a = map === null || map === void 0 ? void 0 : map.value) === null || _a === void 0 ? void 0 : _a.mapObject) === null || _b === void 0 ? void 0 : _b.getZoom()) !== null && _c !== void 0 ? _c : 0;
            if (zoomLevel <= 1.75) {
                dynamicLetterSize.value = "text-xs";
            }
            else if (zoomLevel <= 2.5) {
                dynamicLetterSize.value = "text-md";
            }
            else if (zoomLevel >= 2.5 && zoomLevel <= 4) {
                dynamicLetterSize.value = "text-lg";
            }
            else {
                dynamicLetterSize.value = "text-2xl";
            }
        };
        const onMouseMove = function (event) {
            virtualWellPlateProvider.getData().mouseLocation = event.latlng;
        };
        const onMouseDown = function (event) {
            if (!props.drag || clicked.value) {
                virtualWellPlateProvider.getData().mouseStart = event.latlng;
                clicked.value = !clicked.value;
            }
            else {
                clicked.value = false;
            }
        };
        const onMouseUp = function () {
            if (props.areaSelection)
                emit("onMouseUp");
        };
        const getShapeColor = (id, defaultColor) => {
            let color = defaultColor;
            const shapeIds = virtualWellPlateProvider.getShapeIdsByType(id);
            if (shapeIds.length) {
                color = "#fff";
            }
            if (groups.value.length) {
                const group = getGroupByAreasIds(shapeIds);
                if (group) {
                    color = group.color;
                }
            }
            return color;
        };
        /* Hardcoded lux3ExperimentId
         *  Loads the shapes into the manager
         */
        onMounted(() => {
            window.L.Map.include({
                latLngToLayerPoint: function (latlng) {
                    const projectedPoint = this.project(window.L.latLng(latlng));
                    return projectedPoint._subtract(this.getPixelOrigin());
                }
            });
            initShapes();
        });
        watch(() => props.drag, value => {
            if (map.value) {
                if (value) {
                    map.value.mapObject.dragging.enable();
                }
                else {
                    map.value.mapObject.dragging.disable();
                }
            }
        });
        watch(() => areasPositions.value, () => {
            virtualWellPlateProvider.clearAllShapes();
            selector.deselectAll();
            initShapes();
        }, { deep: true, immediate: true });
        return {
            allWells,
            centerMap,
            map,
            size,
            bounds: [
                [0, 0],
                [85.5, 127.8]
            ],
            crs,
            mapHeight,
            virtualWellPlateProvider,
            thumbnailUrl,
            letters,
            numbers,
            dynamicLetterSize,
            wellWeight,
            wellOpacity,
            wellPlateWeight,
            wellPlateOpacity,
            virtualWellPalette,
            handleZoom,
            onMouseMove,
            onMouseDown,
            onMouseUp,
            mapMounted,
            generateWellPlateBackground,
            generateInnerWellPlateBackground,
            getShapeColor
        };
    }
});
