import { computed, defineComponent, ref } from "vue";
import { LRectangle } from "vue2-leaflet";
import ShapeEnum from "../../_providers/types/enums/ShapeEnum";
import { useVirtualWellplate } from "../../_providers/VirtualWellPlateShapeProvider/withVirtualWellplate";
import { useSelect } from "../../_providers/withSelect/withSelect";
import { getFillColor, getVirtualStrokeColor, virtualWellPalette } from "./index";
import { useGroups } from "@/pages/wellPlate/_providers/withGroups/withGroups";
export default defineComponent({
    components: {
        LRectangle
    },
    props: {
        shape: {
            type: Object,
            required: true
        },
        opacity: {
            type: Number,
            default: 1
        },
        enabledGroupedAreasInteraction: {
            type: Boolean,
            required: false,
            default: true
        },
        isMapView: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["openLux3Experiment", "areaClicked"],
    setup(props, { emit }) {
        const onHover = ref(false);
        const { getGroupByAreasIds } = useGroups();
        const virtualWellPlateProvider = useVirtualWellplate();
        const selector = useSelect();
        const isROI = computed(() => {
            return props.shape.type === ShapeEnum.ROI;
        });
        const selected = computed(() => {
            return selector.selectedAreasIds.value.includes(props.shape.id);
        });
        const grouped = computed(() => getGroupByAreasIds([props.shape.id]));
        const interactive = computed(() => !isROI.value &&
            (props.enabledGroupedAreasInteraction ||
                (!props.enabledGroupedAreasInteraction && !grouped.value)));
        const getRoiStrokeColor = () => {
            return getVirtualStrokeColor(props.shape, onHover, virtualWellPlateProvider.data.groupSelected, true);
        };
        const getShapeStrokeColor = () => {
            if (interactive.value) {
                return getVirtualStrokeColor(props.shape, onHover.value, selected.value, isROI.value, props.isMapView);
            }
            return virtualWellPalette.colorProcessedArea;
        };
        const getStrokeColor = computed(() => {
            if (isROI.value) {
                return getRoiStrokeColor();
            }
            return getShapeStrokeColor();
        });
        const handleClick = () => {
            if (interactive.value && !isROI.value) {
                emit("areaClicked", props.shape.id);
                onHover.value = false;
                onHover.value = true;
            }
        };
        const openLuxExperiment = () => {
            if (interactive.value) {
                emit("openLux3Experiment", props.shape.lux3ExperimentId);
            }
        };
        return {
            onHover,
            getStrokeColor,
            virtualWellPlateProvider,
            isROI,
            selected,
            interactive,
            selector,
            openLuxExperiment,
            getFillColor,
            handleClick
        };
    }
});
